import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";

import { links1 } from "data/dashboard/default";
import ApplinksService from "services/ApplinksService";

import AppLinks from "./AppLinks";

const Dashboard = () => {
  const [appLinks, setAppLinks] = useState([]);

  const setAppLinksSetter = (value) => {
    setAppLinks(value.appLinks);
    console.log(value);
  };

  const msalContext = useMsal();

  useEffect(() => {
    console.log("useEffect");
    ApplinksService.getAll(msalContext, setAppLinksSetter);
  }, []);

  return (
    <>
      <Row className="g-3 mb-3">
        {appLinks.map((group) => (
          <Col md={12} xxl={4}>
            <AppLinks links={group.groupAppLinks} title={group.groupTitle} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Dashboard;
