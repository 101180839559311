import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import SimpleBarReact from "simplebar-react";

const SingleItem = ({ icon, title, description, link }) => {
  return (
    <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3">
      <Flex alignItems="center" className="mb-3">
        <FontAwesomeIcon icon={icon} className="text-primary" />
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          class="stretched-link text-decoration-none"
        >
          <h5 className="fs-0 text-600 mb-0 ps-3">{title}</h5>
        </a>
      </Flex>
      <h5 className="fs--1 text-800">{description}</h5>
    </div>
  );
};

SingleItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

const AppLinks = ({ links, title }) => {
  return (
    <div>
      <Card className="h-100">
        <Card.Header as={Flex} alignItems="center">
          <h5 className="fs-0 fw-normal text-800 mb-0">{title}</h5>
        </Card.Header>
        <Card.Body className="p-0">
          <div className="pt-0 px-x1">
            {links.map((item) => (
              <SingleItem
                key={item.title}
                icon={item.icon}
                title={item.title}
                description={item.description}
                link={item.link}
              />
            ))}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

AppLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape(SingleItem.propTypes)).isRequired,
  title: PropTypes.string.isRequired,
};

export default AppLinks;
