import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import AppContext from 'context/Context';
import classNames from 'classnames';
import NavbarTop from 'components/navbar/top/NavbarTop';
import Footer from 'components/footer/Footer';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import CourseProvider from 'components/app/e-learning/CourseProvider';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  // const isChat = pathname.includes('chat');

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useMsalAuthentication(InteractionType.Redirect);

  return (
    <>
      <AuthenticatedTemplate>
        <div className={isFluid ? 'container-fluid' : 'container'}>
          <ProductProvider>
            <CourseProvider>
              <div className={classNames('content', { 'pb-0': isKanban })}>
                <NavbarTop />
                {/*------ Main Routes ------*/}
                <Outlet />
                {!isKanban && <Footer />}
              </div>
            </CourseProvider>
          </ProductProvider>
        </div>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
          <h1>Allegrone Portal</h1>
          <p>Signing you in...</p>
        </div>
      </UnauthenticatedTemplate>
    </>
  );
};

export default MainLayout;
