import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import Main from "./Main";
import "helpers/initFA";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const container = document.getElementById("main");
const root = createRoot(container);

let config = msalConfig;
config.auth.redirectUri = window.location.origin;

const msalInstance = new PublicClientApplication(config);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Main>
        <App />
      </Main>
    </MsalProvider>
  </React.StrictMode>
);
