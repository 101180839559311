import http from "../httpCommon";
import { silentRequest } from "../authConfig";

// const getAll = (token) => {
//   return http.get("/applinks");
//   //return http.get("/applinks", getConfig(token));
// };

const getAll = (msalContext, setter) => {
  console.log("getAll");
  const accounts = msalContext.accounts;
  const instance = msalContext.instance;

  try {
    const request = {
      ...silentRequest,
      account: accounts[0],
    };
    console.log("hi");

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        console.log("hi");
        http
          .get("/applinks", getConfig(response.accessToken))
          .then((response) => setter(response.data));
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          console.log("Access token (2): " + response.accessToken);
        });
      });
  } catch (err) {
    console.log(err);
  }
};

const getConfig = (token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return config;
};

const ApplinksService = {
  getAll,
};

export default ApplinksService;
